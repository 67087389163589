@import "~ngx-toastr/toastr";

@import "~@angular/material/theming";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
// or
@import "~bootstrap/scss/bootstrap";

// then import toastr scss
@import "~ngx-toastr/toastr-bs4-alert.scss";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

@import "~ngx-toastr/toastr-bs4-alert";

@import "~@ng-select/ng-select/themes/default.theme.css";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$gtdtheme-primary: mat-palette($mat-indigo);
$gtdtheme-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$gtdtheme-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gtdtheme-theme: mat-light-theme(
  (
    color: (
      accent: $gtdtheme-accent,
      primary: $gtdtheme-primary,
      warn: $gtdtheme-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($gtdtheme-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Open Sans, sans-serif;
}
@import "~@angular/material/theming";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import "https://fonts.googleapis.com/css?family=Material+Icons";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600&display=swap");

$fontConfig: (
  display-4: mat-typography-level(112px, 112px, 300, "Open Sans", -0.0134em),
  display-3: mat-typography-level(56px, 56px, 400, "Open Sans", -0.0089em),
  display-2: mat-typography-level(45px, 48px, 400, "Open Sans", 0em),
  display-1: mat-typography-level(28px, 30px, 600, "Open Sans", 0.0074em),
  headline: mat-typography-level(24px, 32px, 400, "Open Sans", 0em),
  title: mat-typography-level(20px, 32px, 500, "Open Sans", 0.0075em),
  subheading-2: mat-typography-level(22px, 28px, 700, "Open Sans", 0.0094em),
  subheading-1: mat-typography-level(15px, 24px, 500, "Open Sans", 0.0067em),
  body-2: mat-typography-level(14px, 24px, 500, "Open Sans", 0.0179em),
  body-1: mat-typography-level(14px, 20px, 400, "Open Sans", 0.0179em),
  button: mat-typography-level(14px, 14px, 500, "Open Sans", 0.0893em),
  caption: mat-typography-level(12px, 20px, 400, "Open Sans", 0.0333em),
  input: mat-typography-level(inherit, 1.125, 400, "Open Sans", 1.5px),
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #ffffff;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#180081, 20%);
$dark-bg-alpha-4: rgba(#180081, 0.04);
$dark-bg-alpha-12: rgba(#180081, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #180081;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#ffffff, 0.04);
$light-bg-alpha-12: rgba(#ffffff, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat-core($fontConfig);

// Theme Config

body {
  --primary-color: #4964e8;
  --primary-lighter-color: #c8d1f8;
  --primary-darker-color: #3148de;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}
$mat-primary: (
  main: #4964e8,
  lighter: #c8d1f8,
  darker: #3148de,
  200: #4964e8,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #2800d6;
  --accent-lighter-color: #bfb3f3;
  --accent-darker-color: #1800c6;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}
$mat-accent: (
  main: #2800d6,
  lighter: #bfb3f3,
  darker: #1800c6,
  200: #2800d6,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #e90000;
  --warn-lighter-color: #f8b3b3;
  --warn-darker-color: #df0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #e90000,
  lighter: #f8b3b3,
  darker: #df0000,
  200: #e90000,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat-palette($mat-warn, main, lighter, darker);

$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include angular-material-theme($theme);

.theme-alternate {
  @include angular-material-theme($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
  .mat-badge-content {
    font-family: "Open Sans", sans-serif;
  }
}
.btn-min-width {
  min-width: 80px;
}
// mat tabs setting
.mat-tab-label {
  justify-content: left !important;

  min-width: 100px !important;
  .mat-tab-label-content {
    justify-content: left !important;
    font-weight: 600;
  }
}
.mat-ink-bar {
  height: 4px !important;
}
.btn {
  font-weight: normal;
}
.card {
  border: none;
}
button,
a,
input,
textarea,
select {
  &:focus {
    outline: none;
  }
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-file {
  padding: 5px 40px;
  font-size: 12px;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.mat-tab-group{
  height: 100%;
}
.mat-tab-body-wrapper {
  flex-grow: 1;
}

.mat-tab-body {
  display: flex !important;
  flex-direction: column;
}

.mat-tab-body-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.c-p {
  cursor: pointer !important;
}


.swal2-icon.swal2-question {
  border-color: #2800d6 !important;
  color: #2800d6 !important;
}

.container-partner {
  padding: 10px !important;
  img {
    cursor: pointer;
    width: 25%;
  }
}

.mat-tab-body-wrapper {
  overflow: initial !important;
}


.cdk-global-overlay-wrapper {
  background-color: #00000080;
}

.mat-tab-label {
  width: 100%;
}
